.mapboxgl-ctrl-bottom-left {
  display: none;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}

@font-face {
  font-family: 'Kragen';
  src: url(../media/fonts/BNKragen-Bold.otf);
}

text.stack-title {
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 600;
  font-size: 28px;
}

.tungsten {
  font-family: 'Tungsten A', 'Tungsten B';
}
g.time-axis {
  font-family: monospace;
  font-weight: 400;
  font-size: 12px;
  text-anchor: middle;
}

main.dashboard {
  width: 100vw;
  height: 100vh;
  background: #ecf2ff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

main.dashboard > div {
  display: flex;
  align-items: center;
}
.dashboard .flex {
  display: flex;
  align-items: center;
}
